import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | About Пекарня Bread Basket
			</title>
			<meta name={"description"} content={"Замесив страсть в каждую буханку - сердце кустарной выпечки"} />
			<meta property={"og:title"} content={"About Us | About Пекарня Bread Basket"} />
			<meta property={"og:description"} content={"Замесив страсть в каждую буханку - сердце кустарной выпечки"} />
			<meta property={"og:image"} content={"https://ozivernoust.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ozivernoust.com/img/4241664.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ozivernoust.com/img/4241664.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ozivernoust.com/img/4241664.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ozivernoust.com/img/4241664.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ozivernoust.com/img/4241664.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ozivernoust.com/img/4241664.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наша история
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					В корзине для хлебной корзины наша история так же богата и наслоена, как ароматы в наших буханках.Это началось с простой миссии: оживить искусство традиционного приготовления хлеба и смешать его с современным творчеством.Наша пекарня, хотя и молодая в годах, несет наследие поколений пекарей, которые верили в магию идеально испеченной буханки хлеба.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ozivernoust.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Создавать воспоминания, по одному буханку за раз
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Путешествие к хлебной корзине в пюре - это не только выпечка хлеба - это о создании воспоминаний.Каждая буханка, которую мы выпекаем, - это повествование о нашей страсти, истории рук, которые тщательно формируют ее, и свидетельство силы качественных ингредиентов.Наша пекарня является символом того, как вечные методы могут сливаться с инновационными рецептами для производства хлеба, который не только питает тело, но и согревает душу.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Больше, чем просто хлеб
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							В то время как мы сосредоточены на хлебе - от деревенских заквасков до сердечных хлеб -ржаков, мы также верим в то, чтобы раздвигать границы выпечки.Наша пекарня является убежищем для тех, кто ценит искусство приготовления хлеба, и для тех, кто хочет исследовать бесчисленные вкусы и текстуры, которые наши руки усовершенствовали.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ozivernoust.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ozivernoust.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Приверженность качеству
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Качество - это не просто слово для нас - это обещание.В корзине для хлебной корзины мы получаем наши ингредиенты от доверенных поставщиков, гарантируя, что каждое зерно и семена вносят вклад в конечный продукт, который мы гордимся.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Присоединяйтесь к нашему путешествию
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Поскольку мы продолжаем писать нашу историю, мы приглашаем вас стать частью этого восхитительного путешествия.Будь то через хлеб, которые украшают ваш стол или моменты, которые вы делитесь в нашей пекарне, для нас большая честь стать частью историй вашей жизни.						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ozivernoust.com/img/8.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});